// https://www.creativebloq.com/how-to/build-a-blogging-site-with-gatsby

import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import SEO from '../../components/SEO';
import Layout from '../../layouts/index';
import LatestPost from '../../components/blog/LatestPost';
import Card from '../../components/blog/Card';

const Blog = ({ data: { allMdx: { edges } } }) => {
  const posts = edges.map(edge => edge.node);
  const latestPost = posts.shift();

  return (
    <Layout bodyClass="blog">
      <SEO title="Blog" />
      <div className="container">
        <LatestPost post={latestPost} />
      </div>

      <div className="container pb-6">
        <div className="row pt-2 justify-content-center">
          <div className="card-deck">
            {posts.map(post => (
              <Card key={post.id} post={post} />
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

Blog.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
};

export const query = graphql`
  query newBlogQuery {
    allMdx(
      filter: { frontmatter: {draft: {eq: false}} }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            date(formatString: "MMM DD, YYYY")
            image
            title
            path
            brief
          }
        }
      }
    }
  }
`;

export default Blog;
