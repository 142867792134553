import React from 'react';
import PropTypes from 'prop-types';
import Link from '../Link';

const Card = ({
  post: {
    frontmatter: {
      title,
      date,
      path,
      image,
      brief,
    },
    id,
  },
}) => (
  <div key={id} className="col-md-6">
    <div className="row no-gutters border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
      <div className="col-6 p-4 d-flex flex-column position-static">
        <h3 className="mb-0">{title}</h3>
        <div className="mb-1 text-muted">{date}</div>
        <p className="card-text mb-auto">{brief}</p>
        <Link key={path} to={path} className="stretched-link">Continue reading</Link>
      </div>
      <div className="col-6 d-none d-lg-block">
        <img src={image} alt={title} />
      </div>
    </div>
  </div>
);

Card.propTypes = {
  post: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
    ]),
  ).isRequired,
};

export default Card;
