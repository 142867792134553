import React from 'react';
import PropTypes from 'prop-types';
import Link from '../Link';

const Latest = ({
  post: {
    frontmatter: {
      title,
      date,
      path,
      image,
      brief,
    },
  },
}) => (
  <div className="jumbotron mt-4 text-white rounded bg-dark" style= {{ backgroundImage: `url(${image})` }}>

    <div className="col-md-6 px-0 p-2" style={{ background: 'rgba(0, 0, 0, .65)', backgroundBlendMode: 'darken', alignItems: 'stretch' }}>
      <h1 className="display-4">{title}</h1>
      <p className="lead">{date}</p>
      <p className="lead my-3">{brief}</p>
      <p className="lead mb-0">
        <Link to={path}>
          Continue reading...
        </Link>
      </p>
    </div>
  </div>
);

Latest.propTypes = {
  post: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
    ]),
  ).isRequired,
};

export default Latest;
